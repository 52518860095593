import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('body')

const Modal = props => {
  const { children, isOpen, ...rest } = props
  const [ref, setRef] = useState(null)

  useEffect(() => {
    if (isOpen && ref) {
      ref.scrollTop = 0
    }
  }, [isOpen, ref])

  return (
    <ReactModal
      bodyOpenClassName="modal-open"
      className="modal-box"
      closeTimeoutMS={300}
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      overlayRef={node => setRef(node)}
      isOpen={isOpen}
      {...rest}
    >
      {children}
    </ReactModal>
  )
}

export default Modal
