import React from 'react'
import cn from 'classnames'

import Container from './Container'
import Bubbles from './Bubbles'
import styles from './Section.module.scss'

const Section = props => {
  const { children, color, bubbles } = props
  const className = cn(styles.section, [styles[color]])

  return (
    <section className={className}>
      <Container className={styles.container}>{children}</Container>
      {bubbles && <Bubbles />}
    </section>
  )
}

export default Section
