import React, { useState, useEffect } from 'react'

import '../styles/index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CallBackModal from '../components/CallBackModal'
import CookieBanner from '../components/CookieBanner'

const SiteLayout = ({
  header = 'light',
  children,
  pageModal,
  setPageModal,
}) => {
  const [modal, setModal] = useState(null)

  useEffect(() => {
    setModal(pageModal)
  }, [pageModal])

  const handleClose = () => {
    if (pageModal) {
      setPageModal(null)
    }

    setModal(null)
  }

  return (
    <>
      <Header type={header} />
      <main className="bg-white">{children}</main>
      <Footer onCallBackClick={() => setModal('call-back')} />
      <CookieBanner />
      {modal && (
        <>
          <CallBackModal
            isOpen={modal === 'call-back'}
            onRequestClose={handleClose}
          />
        </>
      )}
    </>
  )
}

export default SiteLayout
