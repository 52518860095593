import React from 'react'
import { Link } from 'gatsby'

import Container from './Container'
import logo from '../images/logo/logo@1x.png'
import logo1x from '../images/logo/logo@1.5x.png'
import logo2x from '../images/logo/logo@2x.png'
import logo3x from '../images/logo/logo@3x.png'
import twitter from '../images/svg/footer/twitter.svg'
import facebook from '../images/svg/footer/facebook.svg'
import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container size="xl">
        <div className={styles.main}>
          <div className={styles.contactContainer}>
            <div>
              <Link className={styles.logoContainer} to="/">
                <img
                  alt="AirAccounting logo"
                  className={styles.logo}
                  height={38}
                  src={logo}
                  srcSet={`${logo1x} 1.5x, ${logo2x} 2x, ${logo3x} 3x`}
                  width={213}
                />
              </Link>
              <address>
                <p className={styles.telephone}>
                  Call us on <a href="tel:+441384906007">01384 906 007</a>
                </p>
                <p className={styles.email}>
                  Email us on{' '}
                  <a href="mailto:hello@airaccounting.com">
                    hello@airaccounting.com
                  </a>
                </p>
                <p>
                  Our address is Dudley Court South, The Waterfront, Level
                  Street, Brierley Hill, West Midlands, DY5 1XN.
                </p>
              </address>
              <div className={styles.social}>
                <a
                  href="https://twitter.com/airaccountinguk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="twitter logo" />
                </a>
                <a
                  href="https://www.facebook.com/airaccountingteam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="facebook logo" />
                </a>
              </div>
            </div>
            <div className={styles.callBack}>
              <span className={styles.callBackLabel}>Request a call back</span>
              <p>
                Need to talk but can’t quite get to the phone right now? We can
                call you anytime Monday to Friday, 9am to 5pm.
              </p>
              <Link className="btn btn-primary-light" to="/call-back">
                Request a call back
              </Link>
            </div>
          </div>
          <div className={styles.navContainer}>
            <nav className={styles.nav}>
              <div>
                <h3>How it works</h3>
                <Link to="/how-it-works/start-ups">Start ups</Link>
                <Link to="/how-it-works/small-businesses">
                  Small businesses
                </Link>
                <Link to="/how-it-works/sole-traders">Sole traders</Link>
                <Link to="/how-it-works/switching-accountants">
                  Switching accountants
                </Link>
              </div>
              <div>
                <h3>Pricing</h3>
                <Link to="/pricing/small-business-accounting">
                  Small business accounting
                </Link>
                <Link to="/pricing/standalone-services">
                  Standalone services
                </Link>
                <Link to="/pricing/specialised-services">
                  Specialised services
                </Link>
                <Link to="/pricing/build-your-package">Build your package</Link>
              </div>
              <div>
                <h3>Tools & Support</h3>
                <a
                  href="https://central.xero.com/s/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Xero Central
                </a>
                <a
                  href="https://help.receipt-bank.com/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Receipt Bank support
                </a>
                <Link to="/call-back">Request a call back</Link>
              </div>
              <div>
                <h3>Company</h3>
                <Link to="/contact-us">Contact us</Link>
              </div>
            </nav>
          </div>
        </div>
        <hr />
        <div className={styles.legal}>
          <small>
            © Air Accounting Ltd 2016-2019. Registered in England and Wales
            under company number 10256980.
          </small>
          <div className={styles.legalLinks}>
            <Link to="/terms-of-use">Terms of use</Link>
            <Link to="/privacy-policy">Privacy policy</Link>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer

/*
<Link to="/careers">Careers</Link>
<Link to="/about-us">About us</Link>
*/
