import React, { useState, useEffect } from 'react'
import isEmail from 'validator/lib/isEmail'
import cn from 'classnames'
import _ from 'lodash'

import Modal from './Modal'
import Box from './Box'
import usePublicApi from '../hooks/usePublicApi'
import errorImg from '../images/svg/common/error.svg'
import successImg from '../images/svg/common/success.svg'
import styles from './CallBackModal.module.scss'

const CallBackModal = ({ isOpen, onRequestClose }) => {
  const [firstName, setFirstName] = useState('')
  const [firstNameErr, setFirstNameErr] = useState(null)
  const [lastName, setLastName] = useState('')
  const [lastNameErr, setLastNameErr] = useState(null)
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(null)
  const [phone, setPhone] = useState('')
  const [phoneErr, setPhoneErr] = useState(null)
  const [time, setTime] = useState('')
  const [timeErr, setTimeErr] = useState(null)
  const [marketing, setMarketing] = useState(false)
  const [formValid, setFormValid] = useState(false)

  const [
    { isLoading, success, error },
    { sendRequest, resetError },
  ] = usePublicApi()

  const emailCn = cn('form-group required mt-0', {
    disabled: firstNameErr || lastNameErr,
  })
  const phoneCn = cn('form-group col-12 col-sm-6 pr-sm-2 required mt-0', {
    disabled: emailErr || firstNameErr || lastNameErr,
  })
  const timeCn = cn('form-group col-12 col-sm-6 pl-sm-2 required mt-0', {
    disabled: emailErr || firstNameErr || lastNameErr,
  })
  const marketingCn = cn('form-group mt-0', { disabled: !formValid })

  useEffect(() => {
    setFirstNameErr(firstName === '')
    setLastNameErr(lastName === '')
    setEmailErr(!isEmail(email))
    setPhoneErr(phone === '')
    setTimeErr(time === '')

    if (firstNameErr || lastNameErr || emailErr || phoneErr || timeErr) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [
    firstName,
    firstNameErr,
    lastName,
    lastNameErr,
    email,
    emailErr,
    phone,
    phoneErr,
    time,
    timeErr,
  ])

  const handleSubmit = e => {
    e.preventDefault()

    if (formValid) {
      sendRequest({
        method: 'post',
        url: '/marketing/call-back',
        data: { firstName, lastName, email, phone, time, marketing },
      })
    }
  }

  if (success) {
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Box>
          <div className="modal-body">
            <img className={styles.img} src={successImg} alt="success" />
            <h3 className="text-center pt-4 pb-2">We'll be in touch!</h3>
            <p className="text-center">
              We have received your request for our friendly team to give you a
              call. We look forward to speaking to you!
            </p>
            <div className="text-center">
              <button
                className="btn btn-primary"
                onClick={onRequestClose}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Box>
          <div className="modal-body">
            <img className={styles.img} src={errorImg} alt="error" />
            <h3 className="text-center pt-4 pb-2">Oops, that's an error</h3>
            <p className="text-center">
              We're sorry, we encountered an error when trying to send your call
              back request to our team. Please try again.
            </p>
            <div className="text-center">
              <button
                className="btn btn-primary"
                onClick={() => resetError()}
                type="button"
              >
                Try again
              </button>
            </div>
            <small className="text-gray-light mt-2">
              Error code: {_.get(error, 'response.status') || '900'}
            </small>
          </div>
        </Box>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box>
        <div className="modal-header clearfix">
          <button
            className="modal-close"
            onClick={onRequestClose}
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path>
            </svg>
          </button>
          <h3 className="modal-title overflow-auto pr-3">
            Request a call back
          </h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="d-flex flex-wrap">
              <div className="form-group mt-0 pr-sm-2 required col-12 col-sm-6">
                <label className="d-block" htmlFor="firstName">
                  First name
                </label>
                <input
                  className="form-control"
                  disabled={isLoading}
                  id="firstName"
                  onChange={e => setFirstName(e.target.value)}
                  type="text"
                  value={firstName}
                />
              </div>
              <div className="form-group mt-0 col-12 col-sm-6 pl-sm-2 required">
                <label className="d-block" htmlFor="lastName">
                  Last name
                </label>
                <input
                  className="form-control"
                  disabled={isLoading}
                  id="lastName"
                  onChange={e => setLastName(e.target.value)}
                  type="text"
                  value={lastName}
                />
              </div>
            </div>
            <div className={emailCn}>
              <label className="d-block" htmlFor="email">
                Email
              </label>
              <input
                className="form-control long"
                disabled={isLoading || firstNameErr || lastNameErr}
                id="email"
                onChange={e => setEmail(e.target.value)}
                type="text"
                value={email}
              />
              <p className="note">
                We'll use this to confirm your preferred time for us to call.
              </p>
            </div>
            <div className="d-flex flex-wrap">
              <div className={phoneCn}>
                <label className="d-block" htmlFor="phone">
                  Phone
                </label>
                <input
                  className="form-control"
                  disabled={
                    isLoading || emailErr || firstNameErr || lastNameErr
                  }
                  id="phone"
                  onChange={e => setPhone(e.target.value)}
                  type="text"
                  value={phone}
                />
                <p className="note">
                  We'll only use this to contact you when requested.
                </p>
              </div>
              <div className={timeCn}>
                <label className="d-block" htmlFor="time">
                  Best time to call
                </label>
                <input
                  className="form-control"
                  disabled={
                    isLoading || emailErr || firstNameErr || lastNameErr
                  }
                  id="time"
                  onChange={e => setTime(e.target.value)}
                  type="text"
                  placeholder="Wednesday at 10am"
                  value={time}
                />
                <p className="note">
                  We can call you anytime between 9AM to 5PM, Monday to Friday.
                </p>
              </div>
            </div>
            <div className={marketingCn}>
              <p className="d-block text-bold">Want to be kept up to date?</p>
              <p>
                We'd love to keep you up-to-date with offers and news from us
                and our partners. We will never pass your details to any third
                party without your permission.
              </p>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={!!marketing}
                  disabled={isLoading || !formValid}
                  id="marketing"
                  onChange={() => setMarketing(!marketing)}
                />
                <label className="custom-control-label" htmlFor="marketing">
                  Yes please, send me info on offers and services
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-block btn-primary"
              disabled={!formValid || isLoading}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default CallBackModal
